import React from "react"
import {graphql} from "gatsby"
import PageHeader from "../../components/PageHeader/PageHeader";
import styles from "./Page.module.scss";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {richTextOptions} from "../../utils/Utils";
import Container from "../../components/Container/Container";
import {Helmet} from "react-helmet";


export const query = graphql`
query($slug: String!) {
    contentfulPages(slug: { eq: $slug }) {
      title,
      content {
        raw
      },

      textBlocks {
        picture {
          file {
          fileName,
            url
          }
        }
        content {
          raw
        }
      }
    }
  }
`

// references {
//   __typename
// ... on ContentfulAsset {
//     contentful_id
//     file {
//       url,
//         fileName
//     }
//   }
// }
const Page = (props: any) => {
  const renderBlocks = (textBlocks: any[]) => {
    return <>
      {textBlocks.map(block => <div className={styles.block}>
        <img className={styles.blockImage} src={block.picture?.file?.url} alt={block.picture?.file?.fileName}/>
        <div className={styles.blockContent}>{renderRichText(block.content, richTextOptions(styles.image))}</div>
      </div>)}
    </>
  }

  return <>
    <Helmet title={props.data.contentfulPages.title}>
    </Helmet><PageHeader header={props.data.contentfulPages.title}/>
    <Container className={styles.postContent}>
      {props.data.contentfulPages?.content && renderRichText(props.data.contentfulPages.content, richTextOptions())}
      {props.data.contentfulPages?.textBlocks?.length && renderBlocks(props.data.contentfulPages.textBlocks)}
    </Container>
  </>
}

export default Page
